import { GetStaticProps, NextPage } from 'next'
import { HomePageLayout } from '@layouts/Page/HomePageLayout'
import { IGlobalEntity, IHomepageEntity } from '@generated/index'
import { INavigationItemEntity } from '@strapi/navigation'
import { Homepage } from '@utils/strapi/homepage'
import { getAllGlobals } from '@utils/strapi/getAllGlobals'
import { Seo } from '../components/common/Seo'
import { getLocalizationList } from '@utils/localizations'
import { ParsedLocalizations } from '@strapi/localizations'

type Props = {
  homepageEntity: IHomepageEntity
  navigationEntity: INavigationItemEntity[]
  globalEntity: IGlobalEntity
  footerNav1: INavigationItemEntity[]
  footerNav2: INavigationItemEntity[]
  currentLocale: 'en' | 'cs' | 'de'
  localizations: ParsedLocalizations
}

const Index: NextPage<Props> = ({
  homepageEntity,
  navigationEntity,
  globalEntity,
  footerNav1,
  footerNav2,
  currentLocale,
  localizations,
}) => {
  return (
    <>
      {homepageEntity.seo && <Seo {...homepageEntity.seo} />}
      <HomePageLayout
        homePageEntity={homepageEntity}
        navigationEntity={navigationEntity}
        globalEntity={globalEntity}
        footerNav1={footerNav1}
        footerNav2={footerNav2}
        currentLocale={currentLocale}
        localizations={localizations}
      />
    </>
  )
}

// @ts-ignore TODO David
export const getStaticProps: GetStaticProps<Props> = async (context) => {
  const { locale } = context
  const { data: homepageEntity } = await Homepage.get({ locale: locale ?? 'en' })
  const globals = await getAllGlobals(locale ?? 'en')
  if (!homepageEntity || (!homepageEntity.publishedAt && !context.preview)) return { notFound: true, revalidate: false }
  const localizationData = {
    localizations: [
      {
        id: 1,
        name: 'English',
        locale: 'en',
        slug: '/',
      },
      {
        id: 2,
        name: 'Čeština',
        locale: 'cs',
        slug: '/',
      },
      {
        id: 3,
        name: 'Deutsch',
        locale: 'de',
        slug: '/',
      },
    ],
  }
  const localizations = getLocalizationList(localizationData?.localizations, (locale as 'en' | 'cs' | 'de') ?? 'en')

  return {
    props: {
      homepageEntity: homepageEntity,
      currentLocale: locale,
      ...globals,
      localizations: localizations,
    },
    revalidate: false,
  }
}

export default Index
