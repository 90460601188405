import React from 'react'
import { ModulesRenderer } from '../../components/ModulesRenderer/ModulesRenderer'
import { Header } from '@organisms/Header'
import { INavigationItemEntity } from '@strapi/navigation'
import { IGlobalEntity, IHomepageEntity } from '@generated/index'
import { Footer } from '@organisms/Footer'
import { ParsedLocalizations } from '@strapi/localizations'

export type PageLayoutProps = {
  homePageEntity: IHomepageEntity
  navigationEntity: INavigationItemEntity[]
  footerNav1: INavigationItemEntity[]
  footerNav2: INavigationItemEntity[]
  globalEntity: IGlobalEntity
  currentLocale: 'en' | 'cs' | 'de'
  localizations: ParsedLocalizations
}

export const HomePageLayout: React.FC<PageLayoutProps> = ({
  homePageEntity,
  navigationEntity,
  footerNav1,
  footerNav2,
  globalEntity,
  currentLocale,
  localizations,
}) => {
  return (
    <>
      <Header navigationEntity={navigationEntity} currentLocale={currentLocale} localizations={localizations} />
      {homePageEntity.content && <ModulesRenderer modules={homePageEntity.content} />}
      <Footer
        footerNav1={footerNav1}
        footerNav2={footerNav2}
        globalEntity={globalEntity}
        localizations={localizations}
      />
    </>
  )
}
