import { FC } from 'react'
import Head from 'next/head'
import { IComponentSharedSeo } from '@generated/index'
import { getStrapiRoot } from '@utils/env'
import { findValue } from '@utils/findValue'

type SeoProps = IComponentSharedSeo

export const Seo: FC<SeoProps> = ({
  metaTitle,
  metaDescription,
  metaImage,
  metaRobots,
  metaSocial,
  canonicalURL,
  structuredData,
}) => {
  const facebook = findValue(metaSocial, 'socialNetwork', 'Facebook')
  const twitter = findValue(metaSocial, 'socialNetwork', 'Twitter')
  return (
    <Head>
      <title>Webthinx | {metaTitle}</title>
      <meta name="robots" content={metaRobots} />
      <meta name="description" content={metaDescription} />
      <meta name="og:site_name" content="Webthinx" />
      <meta name="og:type" content="website" />
      {canonicalURL && <link rel="canonical" href={canonicalURL} />}
      {structuredData && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />
      )}
      {facebook && (
        <>
          {facebook?.image && <meta name="og:image" content={getStrapiRoot() + facebook.image.url} />}
          {!facebook?.image && metaImage?.url && <meta name="og:image" content={getStrapiRoot() + metaImage.url} />}
          <meta name="og:title" content={facebook.title} />
          <meta name="og:description" content={facebook.description} />
        </>
      )}
      {!facebook && (
        <>
          <meta name="og:title" content={metaTitle} />
          {metaImage?.url && <meta name="og:image" content={getStrapiRoot() + metaImage.url} />}
        </>
      )}
      {twitter && (
        <>
          <meta name="twitter:title" content={twitter.title} />
          <meta name="twitter:description" content={twitter.description} />
          {twitter?.image && <meta name="twitter:image" content={getStrapiRoot() + twitter.image.url} />}
          {!twitter?.image && <meta name="twitter:image" content={getStrapiRoot() + metaImage.url} />}
        </>
      )}
    </Head>
  )
}
